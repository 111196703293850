import { graphql } from 'gatsby';
import * as React from "react";

import Link from 'components/link';
import Pager from 'components/pager';
import Content from "components/templates/Content";
import Layout from 'components/templates/Layout';
import NotFound from 'pages/404';

import BlogFilters from "components/templates/global/BlogFilters";
import { PostsContext } from 'contexts/Posts';
import DataProvider from 'providers/data';
import useLiveData from 'hooks/useLiveData';

const ArticlesPage = (props) => {
	const { edges: pageEdges } = props.data.allContentPage;
	const { edges: topicEdges } = props.data.allBlogTopic;
	const { edges: nodes } = props.data.allBlogPost;
	let posts = nodes.map(({ node }) => node);
	let { pageInfo } = props.data.allBlogPost;
	const { pageContext } = props;
	const pageNodes = pageEdges.map(({ node }) => node);
	const topicNodes = topicEdges.map(({ node }) => node);
	const topics = [{ label: 'None', value: '' }].concat(topicNodes.map(topic => ({ label: topic.name, value: topic.permalink })))
	let page = pageNodes[0];

	const { statusText: pageProviderStatusText, response: pageProviderResponse } = useLiveData({
		type: 'ContentPage',
		apiParams: {
			uri: "/blog/",
		},
		staticData: props.data,
	});

	if ((!!pageProviderResponse !== false && pageProviderResponse.data.length > 0) && pageProviderStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		page = pageProviderResponse.data[0];
	}
	
	if ((!!props.providerResponse !== false && props.providerResponse.data.length > 0) && props.providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		posts = props.providerResponse.data;
		pageInfo = {
			hasPreviousPage: (props.providerResponse.page > 1),
			hasNextPage: (props.providerResponse.page < props.providerResponse.pages),
			perPage: props.providerResponse.pageSize,
			currentPage: props.providerResponse.page,
			totalCoount: props.providerResponse.total,
			pageCount: props.providerResponse.pages,
		};
	}

	const [displayPosts, setDisplayPosts] = React.useState(posts)

	React.useEffect(() => {
		setDisplayPosts(posts)
	}, [posts])

	if (pageInfo.currentPage > pageInfo.pageCount && (props.providerStatusText === 'LOADED' || pageContext.liveRefresh === false)) {
		return <NotFound />
	}
	const pattern = ['/blog/', '/blog/page-$page/'];
	return (
		<Layout>
			<Content page={page} />
			<PostsContext.Provider value={{ posts, topics, setDisplayPosts }}>
				<BlogFilters label={`Find An Article`} />
			</PostsContext.Provider>
			<section id="posts-results">
				<div className="grid-container">
					<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
						{displayPosts.length > 0 && displayPosts.map(post => (
							<div key={post.id} className={`cell`} style={{ marginBottom: '30px' }}>
								<div className="news-card flex-container flex-dir-column align-items">
									<div className="news-photo" style={{ backgroundImage: `url(${post.photo})` }}></div>
									<div className="news-card-content">
										<h2 style={{ alignSelf: 'flex-start' }} className="news-title">{post.title}</h2>
										<p className="news-text">{post.intro}</p>
									</div>
									<Link className="button blue" to={`/blog/${post.permalink}/`}>{(post.cta) ? post.cta : 'Read More'}</Link>
								</div>
							</div>
						))}

					</div>
					{(displayPosts.length === 0 && posts.length > 0) &&
						<div className="grid-container">
							<div className="grid-x grid-padding-x align-center">
								<div className="cell samll-12">
									<h4>No posts match that search criteria</h4>
								</div>
							</div>
						</div>
					}
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

// export default ArticlesPage
const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="BlogPost" apiParams={{ _page: props.pageContext.page, _pageSize: props.pageContext.limit, _sort: "showDate,title", _order: "desc", _join: "Neighborhood,Topic" }}><ArticlesPage {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query blogListQuery ($skip: Int!, $limit: Int!) {
	allContentPage(
	  filter: {uri: {eq: "/blog/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		}
	  }
	}
	allBlogPost(
		filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
		sort: {order: [DESC, DESC], fields: [showDate, title]}
		limit: $limit
		skip: $skip
	) {
	  edges {
		node {
		  id
		  title
		  intro
		  photo
		  uri
		  permalink
		  Topic {
			object {
			  id
			  name
			  permalink
			}
		  }
		  Neighborhood {
			object {
			  id
			  name
			  permalink
			}
		  }
		}
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
	allBlogTopic {
	  edges {
		node {
		  id
		  name
		  permalink
		}
	  }
	}
}
`
//import Select from 'components/templates/form/Select';
import React from "react";
import Select from 'components/templates/form/Select';
import usePostFilter from "hooks/usePostFilter";
import { PostsContext } from 'contexts/Posts';

const BlogFilters = (props) => {

	const neighborhoodOptions = [
		{ label: 'All Neighborhoods', value: '' },
		{ label: 'DeFuniak Springs', value: 'defuniak-springs' },
		{ label: 'Freeport', value: 'freeport' },
		{ label: 'Paxton', value: 'paxton' },
	]

	const { topics } = React.useContext(PostsContext);

	const { handleNeighborhoodChange, handleTopicChange, handleFilter } = usePostFilter()
	
	return (
		<div className="filter-wrapper">
			<div className="grid-container">
				<div className="grid-x grid-padding-x align-center">
					<div className="medium-11 small-10 cell">
						<div className="filter-label">{props.label}</div>
						<div className="flx-wrapper">
							<div className="flx-item">
								<Select onChange={handleNeighborhoodChange} label="Neighborhood" options={neighborhoodOptions} />
							</div>
							<div className="flx-item">
								<Select onChange={handleTopicChange} label="Topic" options={topics} />
							</div>
							<div className="flx-item">
								<label htmlFor="submit" className="invis">Search For Listings</label>
								<button id="submit" onClick={handleFilter} className='button blue'>Search</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogFilters;
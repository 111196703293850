import React from 'react';
import { PostsContext } from 'contexts/Posts';

const  usePostFilter = () => {

	const { posts, setDisplayPosts } = React.useContext(PostsContext);

	const [neighborhoodFilter, setNeighborhoodFilter] = React.useState('');
	const [topicFilter, setTopicFilter] = React.useState('');

	const handleTopicChange = (e) => {
		setTopicFilter(e.target.value)
	}

	const handleNeighborhoodChange = (e) => {
		setNeighborhoodFilter(e.target.value)
	}

	const handleFilter = () => {
		let filteredPosts = posts.concat([]);

		if (neighborhoodFilter) {
			filteredPosts = filteredPosts.filter(listing => listing.Neighborhood?.map(neighborhood => neighborhood.object?.permalink).includes(neighborhoodFilter))
		}

		if (topicFilter) {
			filteredPosts = filteredPosts.filter(listing => listing.Topic?.map(topic => topic.object?.permalink).includes(topicFilter))
		}

		setDisplayPosts(filteredPosts);
		document.getElementById('posts-results').scrollIntoView({ behavior: 'smooth' });
	}

	return { handleNeighborhoodChange, handleTopicChange, handleFilter }
}

export default usePostFilter;